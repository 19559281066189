/* eslint-disable */
// React & Gatsby
import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image';

// Data & Styles
import bannerImage from "../../assets/images/banner@1600x700.png";


class Hero extends Component {
    render() {
        let backgroundImage = bannerImage;
        if (this.props.bgImage) {
            backgroundImage = this.props.bgImage;
        }
        if (this.props.bgImage === false) {
            backgroundImage = '';
        }

        return (
            <React.Fragment>
                <Container
                    fluid
                    className={'header-container content justify-content-center'}
                    style={{
                        backgroundColor    : this.props.bgColor || '#c1dff4',
                        backgroundImage    : this.props.bgImage === false ? '' : `url(${backgroundImage})`,
                        backgroundPosition : 'center bottom',
                        backgroundRepeat   : 'no-repeat',
                        height             : this.props.height ? this.props.height : ''
                    }}
                >
                    <Row className={'justify-content-center mr-0 ml-0'}>
                        <Col lg={'auto'} className={'text-center'}>
                            {this.props.heading || ''}
                            {this.props.subheading || ''}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default Hero
