/* eslint-disable */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// React & Gatsby
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

// Custom
import Page from "../components/Layout/Page"
import Products from '../components/Content/Products';

// Date & Styles
import "../assets/scss/App.scss"
import Hero from "../components/Hero";


class PremiumIconsPage extends Component {
    render() {
        const heading = <h1>Premium Vector Icons & Avatars</h1>;
        const subheading = <h2>Royalty-free vector icons for web and apps</h2>;
        return (
            <Page hero={<Hero heading={heading} subheading={subheading} />}>
                <Container className={'mt-4 mb-4'}>
                    <Products category={"premium-icons"} colWidth={4} />
                </Container>
            </Page>
        )
    }
}

export default PremiumIconsPage
